import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

// import {useContext} from "react";
// import Map from './components/map/Map';
import Chart from './components/chart/Chart';
import ChartC from './components/chart/ChartC';

import ChartNew from './components/chart/ChartNew';
import Table from './components/table/Table';
import TableWithChart from './components/table/TableWithChart';
import Map from './components/map/Map';
import BarChart from './components/table/BarChart';
import TableChargingAmount from "./components/table/TableChargingAmount";
import dayjs from "dayjs";
import {useAuth} from "../../modules/auth";


const dataOrderLeft = [
  {
    cityCode: "11",
    cityName: "서울시",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "28",
    cityName: "인천시",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "44",
    cityName: "충청남도",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "36",
    cityName: "세종",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "30",
    cityName: "대전시",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "52",
    cityName: "전라북도",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "29",
    cityName: "광주시",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "46",
    cityName: "전라남도",
    fastCount: 0,
    slowCount: 0
  },


]

const dataOrderRight = [
  {
    cityCode: "51",
    cityName: "강원도",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "41",
    cityName: "경기도",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "43",
    cityName: "충북",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "47",
    cityName: "경상북도",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "27",
    cityName: "대구시",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "31",
    cityName: "울산시",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "26",
    cityName: "부산시",
    fastCount: 0,
    slowCount: 0
  },
  {
    cityCode: "48",
    cityName: "경상남도",
    fastCount: 0,
    slowCount: 0
  },
]

const dataOrderJeju = {
  cityCode: "50",
  cityName: "제주도",
  fastCount: 0,
  slowCount: 0
}


const SungKwangPage = ({data}) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [location, setLocation] = useState('')

  const [legendLeft, setLegendLeft] = useState<any | null>(null);	//왼쪽 지도 범례
  const [legendRight, setLegendRight] = useState<any | null>(null);	//오른쪽 지도 범례
  const [legendJeju, setLegendJeju] = useState<any | null>(null);	//제주 지도 범례

  const [currentLocation, setCurrentLocation] = useState<any | null>('');	//현재 선택된 위치

  const [stationKind, setStationKind] = useState<any | null>(null);	//위치별 충전소 개수
  const [chargerType, setChargerTypeCount] = useState<any | null>(null);	//위치별 충전소 개수
  const [chargerStatus, setChargerStatus] = useState<any | null>(null);	//위치별 충전소 개수

  const [chargingHistory, setChargingHistory] = useState<any | null>([]);	//위치별 충전소 개수

  const [barChartData, setBarChartData] = useState<any | null>(null);	//위치별 충전소 개수
  const [chargingState, setChargingState] = useState<any | null>(null);	//위치별 충전소 개수
  const [weekCharging, setWeekCharging] = useState<any | null>(null);	//위치별 충전소 개수

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/tailwind.standalone.js";
    script.async = true;
    document.body.appendChild(script);
  }, [])

  const {currentUser} = useAuth()

  useEffect(() => {
    fetch(`${apiUrl}/dashBoard/getChargerByCityCount`, {
      method: 'POST',
    }).then(response => response.json())
      .then(data => {
        let jeju = null

        let lebel: string[] = [];
        let dataValues1: Number[] = [];
        let dataValues2: Number[] = [];


        data.map(item => {
          lebel.push(item.cityName)
          const val = Number(item.fastCount)
          const val2 = Number(item.slowCount)
          dataValues1.push(val)
          dataValues2.push(val2)
        });
        const barDataSet = {
          lebel,
          datasets: [
            {
              label: '급속',
              data: dataValues1,
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
              label: '완속',
              data: dataValues2,
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
          ],
        };

        setBarChartData({...barDataSet});

        const leftSet = dataOrderLeft.map(legend => {
          data.map(inner => {
            if (legend.cityCode === inner.cityCode) {
              legend.fastCount = inner.fastCount;
              legend.slowCount = inner.slowCount;
            }
            if (inner.cityCode === '50') {
              jeju = inner
            }
          })
          return legend
        })
        setLegendLeft(leftSet)

        const rightSet = dataOrderRight.map(legend => {
          data.map(inner => {
            if (legend.cityCode === inner.cityCode) {
              legend.fastCount = inner.fastCount;
              legend.slowCount = inner.slowCount;
            }
            if (inner.cityCode === '50') {
              jeju = inner
            }
          });
          return legend
        });
        setLegendJeju(jeju)
        setLegendRight(rightSet)
      })
      .catch(error => console.error('Error:', error));
  }, [location]);

  useEffect(() => {

    let url = `${apiUrl}/dashBoard/getStationKindCountByCity`;
    if (currentLocation !== '') {
      url = `${apiUrl}/dashBoard/getStationKindCountByCity?cityCode=${currentLocation}`;
    }

    fetch(url, {
      method: 'POST',
    }).then(response => response.json())
      .then(data => {
        let lebel: string[] = [];
        let dataValues: Number[] = [];
        data.map(item => {
          lebel.push(item.codeName)
          const val = Number(item.chargerCount)
          dataValues.push(val)
        })
        const chartDataSet = {
          labels: lebel,
          data: dataValues,
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(54, 162, 86)',
            'rgb(168, 85, 247)',
            'rgb(244, 63, 94)',
            'rgb(244, 63, 86)',
            'rgb(168, 63, 235)',
          ],
          unit: '개소'
        }
        setStationKind(chartDataSet)
      });
  }, [currentLocation]);


  useEffect(() => {
    let url = `${apiUrl}/dashBoard/getChargerSpeedCountByCity`;
    if (currentLocation !== '') {
      url = `${apiUrl}/dashBoard/getChargerSpeedCountByCity?cityCode=${currentLocation}`;
    }

    fetch(url, {
      method: 'POST',
    }).then(response => response.json())
      .then(data => {
        let lebel: string[] = [];
        let dataValues: Number[] = [];
        data.map(item => {
          lebel.push(item.speedStr)
          const val = Number(item.chargerCount)
          dataValues.push(val)
        })
        const chartDataSet = {
          labels: lebel,
          data: dataValues,
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
          ],
          unit: '대'
        }
        setChargerTypeCount(chartDataSet)
      });
  }, [currentLocation]);

  useEffect(() => {
    let url = `${apiUrl}/dashBoard/getChargerStatusCountByCity`;
    if (currentLocation !== '') {
      url = `${apiUrl}/dashBoard/getChargerStatusCountByCity?cityCode=${currentLocation}`;
    }


    const exclude = ['SuspendedEVSE', 'SuspendedEV', 'Reserved'];

    fetch(url, {
      method: 'POST',
    }).then(response => response.json())
      .then(data => {
        let lebel: string[] = [];
        let dataValues: Number[] = [];

        data.filter(item => !exclude.includes(item.codeName)).map(item => {
          lebel.push(item.codeName)
          const val = Number(item.chargerCount)
          dataValues.push(val)
        });
        // data.map(item=>{
        // 	console.log('include=>',exclude.includes(item.codeName));
        // 	lebel.push(item.codeName)
        // 	const val = Number(item.chargerCount)
        // 	dataValues.push(val)
        // });


        const chartDataSet = {
          labels: lebel,
          data: dataValues,
          backgroundColor: [
            '#ff6384',	//충전시작
            '#36a2eb',	//충전중
            '#ffcd56',	//충전완료
            '#34d399',	//언플러그
            '#a855f7',	//충전대기
            '#fb7185',	//점검중
            '#dc2626',	//장애
            '#a83feb',	//연결됨
            '#a8a29e',	//연결종료
            '#020617',	//알수없음
            '#10b981',	//준비중

          ],
          unit: '대'
        }
        // console.log('chartDataSet', chartDataSet)
        setChargerStatus(chartDataSet)
        // chargerStatus,setChargerStatus
      });
  }, [currentLocation]);

  const now = dayjs();
  const startOfMonth = now.startOf('month');
  const endOfMonth = now.endOf('month');

  const startTimeStamp = startOfMonth.format('YYYY-MM-DD 00:00:00');
  const endTimeStamp = endOfMonth.format('YYYY-MM-DD 23:59:59');


  useEffect(() => {
    // let url = `${apiUrl}/dashBoard/getRecentChargingLogByCity`;
    let url = `${apiUrl}/statistics/dailyListDashBoard`;
    if (currentLocation !== '') {
      // url = `${apiUrl}/dashBoard/getRecentChargingLogByCity?cityCode=${currentLocation}`;
      // url = `${apiUrl}/dashBoard/getRecentChargingLogByCity?cityCode=${currentLocation}`;
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        managerAccount: currentUser?.managerAccount,
        cityCode: currentLocation,
      }),
    }).then(response => response.json())
      .then(data => {
        setChargingHistory(data)
      });
  }, [currentLocation]);

  useEffect(() => {
    let url = `${apiUrl}/statistics/dailyChartDashBoard`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        managerAccount: currentUser?.managerAccount,
        cityCode: currentLocation,
      }),
    }).then(response => response.json())
      .then(data => {
        console.log('dailyListDashBoard', currentLocation, data)
        setWeekCharging(data)
      });
  }, [currentLocation]);


  useEffect(() => {
    let url = `${apiUrl}/dashBoard/getChargingCountByCity`;
    if (currentLocation !== '') {
      url = `${apiUrl}/dashBoard/getChargingCountByCity?cityCode=${currentLocation}`;
    }

    fetch(url, {
      method: 'POST',
    }).then(response => response.json())
      .then(data => {
        setChargingState(data)
        // setChargingHistory(data)
        // chargingState,
      });
  }, [currentLocation]);


  return (
    <>
      <div className='sm:flex '>
        <div className=''>
          <div className='relative '>
            <Map
              legendLeft={legendLeft} legendRight={legendRight} legendJeju={legendJeju}
              currentLocation={currentLocation}
              setCurrentLocation={setCurrentLocation}
            />
            <BarChart data={barChartData}/>
          </div>
        </div>

        <div className=' mb-4 sm:mb-0 mr-4'>
          <div className='' style={{marginBottom: '22px'}}>
            <ChartNew chartData={stationKind} title="충전소"/>
          </div>
          <div className='' style={{marginBottom: '22px'}}>
            {/* <ChartC chartData={chargerType}  title=""/> */}
            <ChartNew chartData={chargerType} title="충전기"/>
          </div>
          <div className=''>
            <ChartNew chartData={chargerStatus} title="충전상태"/>
          </div>
        </div>
        <div className='   w-full sm:w-[700px]'>
          <div className='' style={{minHeight: '', marginBottom: '24px'}}>
            <TableChargingAmount data={chargingState}/>
          </div>
          <div className='' style={{minHeight: '', marginBottom: '24px'}}>
            <Table data={chargingHistory}/>
          </div>
          <div className='' style={{marginBottom: '24px'}}>
            <TableWithChart data={weekCharging}/>
          </div>
        </div>
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {/* <DashboardPage /> */}
      <SungKwangPage data={undefined}/>
    </>
  )
}

export {DashboardWrapper}
