import dayjs from 'dayjs';

export default function Table({ data }) {
    return (
        <div className="relative bg-white shadow-xl px-8 pt-4 pb-9 rounded-xl w-full sm:w-[600px]">
            <div className='mb-1'>
                <div className='text-md font-bold tracking-tight text-slate-700 inline-block align-middle'>
                    일자별 충전량
                </div>
            </div>
            <div className="mt-4 overflow-hidden">
                <div className="-mx-4 -my-2 overflow-x-hidden sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                        <div className="shadow ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="overflow-y-auto h-[230px]">
                                <table className="min-w-full divide-y divide-gray-100 table-fixed">
                                    <thead className="bg-gray-50 sticky top-0">
                                    <tr>
                                        <th className="py-3 px-4 pr-3 text-left text-sm font-semibold text-slate-500 text-center w-1/4">
                                            일자
                                        </th>
                                        <th className="px-3 py-1 text-left text-sm font-semibold text-slate-500 text-center w-1/4">
                                            충전량(kW)
                                        </th>
                                        <th className="px-3 py-1 text-left text-sm font-semibold text-slate-500 text-center w-1/4">
                                            충전 횟수
                                        </th>
                                        <th className="px-3 py-1 text-left text-sm font-semibold text-slate-500 text-center w-1/4">
                                            매출액(원)
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-100">
                                    {data.map((item, i) => (
                                        <tr key={i}>
                                            <td className="px-3 whitespace-nowrap py-2 text-md text-gray-700 font-bold text-center w-1/4">
                                                {dayjs(item.chargingDate).format('YYYY-MM-DD')}
                                            </td>
                                            <td className="px-3 whitespace-nowrap py-2 text-md text-gray-700 font-semibold text-center w-1/4">
                                                {Number(item.totalKwh).toLocaleString()}
                                            </td>
                                            <td className="px-3 whitespace-nowrap py-2 text-md text-slate-600 font-semibold text-center w-1/4">
                                                {Number(item.totalCount).toLocaleString()}
                                            </td>
                                            <td className="px-3 whitespace-nowrap py-2 text-md text-slate-600 font-semibold text-center w-1/4">
                                                {Number(item.totalAmount).toLocaleString()}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
