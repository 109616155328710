import MixedChart from './MixedChart';


  const chartData = {
	labels:['업무용','공용','아파트','전기버스'],
	data:[300, 50, 100,70],
	backgroundColor: [
		'rgb(255, 99, 132)',
		'rgb(54, 162, 235)',
		'rgb(255, 205, 86)',
		'rgb(54, 162, 86)',
	],
	unit:'개소'
}

const currentMonth = new Date().getMonth() + 1;
  export default function TableChargingAmount({data}) {


	if(!data) {
		return null
	}
	return (
		<div className="relative bg-white shadow-xl  px-8 py-4 rounded-xl w-full sm:w-[600px] h-[280px]" style={{}}>
		{/*<div className="relative bg-white shadow-xl  px-8 py-4 rounded-xl w-full mx-auto xl:w-[600px] h-[525px] overflow-y-auto" style={{}}>*/}
		<div className='mb-1'>
			<div className='relative'>
						<div className='text-md font-bold tracking-tight  text-slate-700 inline-block align-middle'>
						누적 충전량 ( {currentMonth}월 누계 )
						</div>
						<div className='inline-block cursor-pointer' >
							{/*<ArrowUpRightIcon className='w-3 inline align-middle ml-2 '/>*/}
						</div>
					</div>
					{/* <div className='text-sm tracking-tight mb-1 text-gray-400'>
					테이블과 차트가 표시됩니다.
					</div> */}
		</div>

		<div className="mt-2 flow-root">
			<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8  ">
			  <table className=" divide-y  divide-gray-100 min-w-full">
				<thead>
				<tr className="">
					<th scope="col" className="py-4 px-3 pr-3 text-left text-lg font-semibold text-slate-500 text-center ">
						구분
					</th>
					<th scope="col" className="px-3 py-1 text-left text-lg font-semibold text-slate-500 text-center">
						{data[0].speedStr}
					</th>
					<th scope="col" className="px-3 py-1 text-left text-lg font-semibold text-slate-500 text-center">
						{data[1].speedStr}
					</th>
					<th scope="col" className="px-3 py-1 text-left text-lg font-semibold text-slate-500 text-center">
						합계
					</th>
					{/*<th scope="col" className="px-3 py-1 text-left text-lg font-semibold text-slate-500">*/}
					{/*{data[2].speedStr}*/}
					{/*</th>*/}
				</tr>
				</thead>
				  <tbody className="divide-y divide-gray-100">
				  <tr className="  ">
					  <td className="bg-opacity-50   px-3 whitespace-nowrap py-2  text-lg font-medium text-slate-700  text-center">
						충전전력
					</td>
					<td className="px-3  whitespace-nowrap  py-2 text-lg text-slate-600 font-semibold  text-center">
						{Number(data[0].chargingKwh).toFixed(3).toLocaleString()}kW
					</td>
					<td className="px-3 whitespace-nowrap  py-2 text-lg text-slate-600 font-semibold text-center">
						{Number(data[1].chargingKwh).toFixed(3).toLocaleString()}kW
					</td>
					<td className="px-3 whitespace-nowrap  py-2 text-lg text-slate-600 font-semibold text-center">
						{Number(parseFloat(data[0].chargingKwh) + parseFloat(data[1].chargingKwh)).toFixed(3).toLocaleString()}kW</td>
					{/*<td className="px-3 whitespace-nowrap  py-2 text-lg text-slate-700 font-bold">{data[2].chargingKwh}</td>*/}
				</tr>
				<tr className="  ">
					<td className="bg-opacity-50   px-3 whitespace-nowrap py-2  text-lg font-medium text-slate-700  text-center">
					  충전횟수
					  </td>
					  <td className="px-3  whitespace-nowrap  py-2 text-lg text-slate-600 font-semibold  text-center">
						  {Number(data[0].chargingCount).toLocaleString()}회
					  </td>
					  <td className="px-3 whitespace-nowrap  py-2 text-lg text-slate-600 font-semibold  text-center" >
						  {Number(data[1].chargingCount).toLocaleString()}회
					  </td>
					  <td className="px-3 whitespace-nowrap  py-2 text-lg text-slate-600 font-semibold  text-center" >
						  {Number(parseInt(data[0].chargingCount) + parseInt(data[1].chargingCount)).toLocaleString()} 회
					  </td>
					  {/*<td className="px-3 whitespace-nowrap  py-2 text-lg text-slate-700 font-bold">{data[2].chargingCount}</td>*/}
					</tr>
					<tr className="  ">
					  <td className="bg-opacity-50   px-3 whitespace-nowrap py-2  text-lg font-medium text-slate-700  text-center">
					  판매금액
					  </td>
					  <td className="px-3  whitespace-nowrap  py-2 text-lg text-slate-600 font-semibold  text-center ">
						  {Number(data[0].chargingWon).toLocaleString()}원
					  </td>
					  <td className="px-3 whitespace-nowrap  py-2 text-lg text-slate-600 font-semibold  text-center" >
						  {Number(data[1].chargingWon).toLocaleString()}원
					  </td>
					  <td className="px-3 whitespace-nowrap  py-2 text-lg text-slate-600 font-semibold text-center" >
						  {Number(parseInt(data[0].chargingWon) + parseInt(data[1].chargingWon)).toLocaleString()}원
					  </td>
					</tr>
				</tbody>
			  </table>
			</div>
		  </div>
		</div>
	  </div>
	)
  }
