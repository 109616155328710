import React from 'react';
import './map.css';
import { mapPath } from './mapPath';

const regions = {
  "11": {
    path: mapPath.seoul,
    lines: [
      { x1: 150, y1: -100, x2: -190, y2: -100 },
      { x1: 150, y1: -100, x2: 150, y2: 214 }
    ],
    text: { x: 156, y: 214, label: "서울특별시" }
  },
  "26": {
    path: mapPath.busan,
    lines: [
      { x1: 503, y1: 710, x2: 503, y2: 940 },
      { x1: 503, y1: 940, x2: 750, y2: 940 }
    ],
    text: { x: 503, y: 695, label: "부산광역시" }
  },
  "27": {
    path: mapPath.daegu,
    lines: [
      { x1: 420, y1: 570, x2: 420, y2: 590 },
      { x1: 420, y1: 590, x2: 750, y2: 590 }
    ],
    text: { x: 418, y: 567, label: "대구광역시" }
  },
  "28": {
    path: mapPath.incheon,
    lines: [
      { x1: 67, y1: 80, x2: -190, y2: 80 },
      { x1: 67, y1: 80, x2: 67, y2: 180 }
    ],
    text: { x: 67, y: 177, label: "인천광역시" }
  },
  "29": {
    path: mapPath.gwangju,
    lines: [
      { x1: 100, y1: 707, x2: 50, y2: 707 },
      { x1: 50, y1: 707, x2: 50, y2: 930 },
      { x1: 50, y1: 930, x2: -190, y2: 930 }
    ],
    text: { x: 127, y: 707, label: "광주광역시" }
  },
  "30": {
    path: mapPath.daejeon,
    lines: [
      { x1: 190, y1: 463, x2: 10, y2: 463 },
      { x1: 10, y1: 463, x2: 10, y2: 590 },
      { x1: 10, y1: 590, x2: -190, y2: 590 }
    ],
    text: { x: 221, y: 463, label: "대전광역시" }
  },
  "31": {
    path: mapPath.ulsan,
    lines: [
      { x1: 560, y1: 630, x2: 560, y2: 770 },
      { x1: 560, y1: 770, x2: 750, y2: 770 }
    ],
    text: { x: 531, y: 622, label: "울산광역시" }
  },
  "36": {
    path: mapPath.sejong,
    lines: [
      { x1: 150, y1: 425, x2: -190, y2: 425 }
    ],
    text: { x: 199, y: 418, label: "세종특별자치시" }
  },
  "41": {
    path: mapPath.gyeonggi,
    lines: [
      { x1: 216, y1: 200, x2: 590, y2: 200 },
      { x1: 216, y1: 245, x2: 216, y2: 200 },
      { x1: 590, y1: 90, x2: 590, y2: 200 },
      { x1: 590, y1: 90, x2: 750, y2: 90 }
    ],
    text: { x: 216, y: 245, label: "경기도" }
  },
  "51": {
    path: mapPath.gangwon,
    lines: [
      { x1: 370, y1: -100, x2: 750, y2: -100 },
      { x1: 370, y1: 170, x2: 370, y2: -100 }
    ],
    text: { x: 370, y: 179, label: "강원도" }
  },
  "43": {
    path: mapPath.chungbuk,
    lines: [
      { x1: 294, y1: 381, x2: 294, y2: 250 },
      { x1: 294, y1: 250, x2: 750, y2: 250 }
    ],
    text: { x: 294, y: 381, label: "충청북도" }
  },
  "44": {
    path: mapPath.chungnam,
    lines: [
      { x1: 90, y1: 250, x2: -190, y2: 250 },
      { x1: 90, y1: 250, x2: 90, y2: 370 }
    ],
    text: { x: 105, y: 380, label: "충청남도" }
  },
  "45": {
    path: mapPath.jeonbuk,
    lines: [
      { x1: 150, y1: 610, x2: 0, y2: 610 },
      { x1: 0, y1: 610, x2: 0, y2: 770 },
      { x1: 0, y1: 770, x2: -190, y2: 770 }
    ],
    text: { x: 179, y: 592, label: "전라북도" }
  },
  "46": {
    path: mapPath.jeonnam,
    lines: [
      { x1: 138, y1: 764, x2: 138, y2: 1000 },
      { x1: 138, y1: 1000, x2: -80, y2: 1000 },
      { x1: -80, y1: 1000, x2: -80, y2: 1100 },
      { x1: -80, y1: 1100, x2: -190, y2: 1100 }
    ],
    text: { x: 138, y: 764, label: "전라남도" }
  },
  "47": {
    path: mapPath.gyeongbuk,
    lines: [
      { x1: 490, y1: 430, x2: 750, y2: 430 }
    ],
    text: { x: 447, y: 435, label: "경상북도" }
  },
  "48": {
    path: mapPath.gyeongnam,
    lines: [
      { x1: 367, y1: 690, x2: 367, y2: 1100 },
      { x1: 367, y1: 1100, x2: 750, y2: 1100 }
    ],
    text: { x: 367, y: 672, label: "경상남도" }
  },
  "50": {
    path: mapPath.jeju,
    lines: [
      { x1: 120, y1: 1070, x2: 160, y2: 1070 }
    ],
    text: { x: 76, y: 1070, label: "제주특별자치도" }
  }
};

const MapSVG = ({ currentLocation, setCurrentLocation }) => {
  return (
    <svg
      style={{ overflow: 'visible' }}
      height={700}
      width={800}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter id="">
          <feGaussianBlur in="SourceAlpha" stdDeviation={3} />
          <feOffset dx={2} dy={2} result="offsetblur" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g filter="">
        {Object.entries(regions).map(([key, region]) => (
          <React.Fragment key={key}>
            <path
              onClick={() => setCurrentLocation(key)}
              id={`CD${key}`}
              className={`OUTLINE si-do transition-all delay-150 ease-in-out ${currentLocation === key ? 'selected' : ''}`}
              d={region.path}
            />
          </React.Fragment>
        ))}
        {Object.entries(regions).map(([key, region]) => (
          <React.Fragment key={key}>
            <text
              id={`LCD${key}`}
              className={`TEXT ${currentLocation === key ? 'selected' : ''}`}
              x={region.text.x}
              y={region.text.y}
              onClick={() => setCurrentLocation(key)}
            >
              {region.text.label}
            </text>
          </React.Fragment>
        ))}
        {Object.entries(regions).map(([key, region]) => (
          <React.Fragment key={key}>
            {region.lines.map((line, index) => (
              <line
                key={index}
                fill="none"
                stroke={currentLocation === key ? '#6366f1' : '#000'}
                strokeWidth={currentLocation === key ? '4' : '2'}
                x1={line.x1}
                y1={line.y1}
                x2={line.x2}
                y2={line.y2}
              />
            ))}
          </React.Fragment>
        ))}
      </g>
    </svg>
  );
};

export default MapSVG;
