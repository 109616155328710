import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
} from 'chart.js';
import {Chart} from 'react-chartjs-2';
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);


const options = {
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
      title: {
        display: true,
        text: 'kW',
      },
    },
    'y-axis-2': {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      title: {
        display: true,
        text: '매출액',
      },
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  plugins: {
    title: {
      display: true,
      text: `${dayjs().format('YYYY년 M월 D일')} 2주간 충전현황`,
      font: {
        size: 16
      }
    },
    // legend: {
    //   position: 'top',
    // },
    // tooltip: {
    //   mode: 'index',
    //   intersect: false,
    // },
  },
};

export default function TableWithChart({data}) {
  if (!data) {
    return null
  }

  const labels = data.map(item => item.weekName);
  const prevKwhData = data.map(item => item.prevKwh);
  const currKwhData = data.map(item => item.currKwh);
  const prevAmountData = data.map(item => item.prevAmount);
  const currAmountData = data.map(item => item.currAmount);

  const dataValue = {
    labels: labels,
    datasets: [
      {
        type: 'bar' as const,
        label: '금주 충전량',
        data: currKwhData,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        stack: 'Stack 0',
      },
      {
        type: 'bar' as const,
        label: '전주 충전량',
        data: prevKwhData,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        stack: 'Stack 0',
      },
      {
        type: 'line' as const,
        label: '금주 매출액',
        data: currAmountData,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192,1 )',
        yAxisID: 'y-axis-2',
      },
      {
        type: 'line' as const,
        label: '전주 매출액',
        data: prevAmountData,
        borderColor: 'rgba(255, 206, 86, 1)',
        backgroundColor: 'rgba(255, 206, 86, 1)',
        yAxisID: 'y-axis-2',
      },
    ],
  };


  return (
    <div className="relative bg-white shadow-xl px-8 py-4 rounded-xl w-full sm:w-[600px] h-[280px]">
      <Chart type='bar' data={dataValue} options={options}/>
    </div>
  )
}
